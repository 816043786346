<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ product.title }}</v-card-title>
                <v-card-text>
                    <ProductTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <td>Название</td>
                                    <td>Артикул</td>
                                    <td></td>
                                    <!-- <td></td> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in productSizes" :key="item.id">
                                    <td>{{ item.gender.title }} - {{ item.title }}</td>
                                    <td>{{ item.code }}</td>
                                    <td align="right">
                                        <v-icon color="primary" @click="remove(item.product_size_id)">mdi-trash-can</v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn color="primary" class="mr-3" @click="create" v-if="filteredSizes.length">
                            <v-icon>mdi-plus</v-icon> Добавить
                        </v-btn>
                        <v-btn color="light" class="mr-3" @click="goBack" >Назад</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>

        <v-dialog
            v-model="editModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="primary">
                    Размер
                </v-card-title>

                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-select
                                    v-model="editProductSize.size_id"
                                    label="Размер"
                                    :items="filteredSizes"
                                    item-text="title"
                                    item-value="id"
                                    outlined
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="primary"
                        class="mr-3"
                        @click="save"
                    >Сохранить</v-btn>
                    <v-btn
                        color="light"
                        class="ml-3"
                        @click="editModal = false"
                    >Отмена</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import store from '@/store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import ProductTabLinks from '@/components/products/ProductTabLinks';

    export default {
        name: 'ProductSizes',

        components: {
            ProductTabLinks,
        },

        data() {
            return {
                editModal: false,
                editProductSize: {}
            };
        },

        computed: {
            ...mapState('products', {
                product: state => state.entity
            }),
            ...mapState('sizes', {
                sizes: state => state.entities
            }),
            productSizes() {
                return this.product && this.product.sizes ? this.product.sizes : [];
            },
            filteredSizes() {
                if(this.product && this.product.sizes) {
                    const sizeIds = this.product.sizes.map(item => item.id);
                    return this.sizes.filter(item => {
                        return this.product.gender_id === item.gender_id && sizeIds.indexOf(item.id) < 0;
                    }).map(item => ({
                        ...item,
                        title: item.gender.title + ' - ' + item.title
                    }));
                }
                return this.sizes.filter(item => {
                    return this.product.gender_id === item.gender_id;
                }).map(item => ({
                    ...item,
                    title: item.gender.title + ' - ' + item.title
                }));
            }
        },

        async mounted() {
            await store.dispatch('products/get', { id: this.$route.params.id });
            await store.dispatch('sizes/fetch');
        },

        methods: {
            create () {
                this.editProductSize = {
                    product_id: this.product.id,
                    size_id: ''
                };
                this.editModal = true;
            },
            edit(item) {
                this.editProductSize = {
                    id: item.product_size_id,
                    product_id: this.product.id,
                    size_id: item.id
                };
                this.editModal = true;
            },
            async save() {
                const { id } = this.$route.params;
                try {
                    store.commit('productSizes/SET_ENTITY', this.editProductSize);
                    await store.dispatch('productSizes/save', { product_id: id });
                    await store.dispatch('products/get', { id });
                    this.editModal = false;
                } catch (error) {
                    alert('Ошибка сохранения');
                }
            },
            async remove(product_size_id) {
                const { id } = this.$route.params;
                try {
                    await store.dispatch('productSizes/delete', { product_id: id, id: product_size_id });
                    await store.dispatch('products/get', { id });
                } catch (error) {
                    alert('Ошибка удаления');
                }
            },
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            goBack() {
                const { id } = this.$route.params;
                if(id) {
                    this.$router.push(`/products/${id}/detail`);
                } else {
                    this.$router.push('/products');
                }
            },
        },
    };
</script>